// extracted by mini-css-extract-plugin
export const competitorPage = "competitors-module--competitor--page--2ES5w";
export const competitorBlock = "competitors-module--competitor--block--30zxu";
export const competitorImage = "competitors-module--competitor-image--3yxAs";
export const competitorsLink = "competitors-module--competitors--link--yK4Vg";
export const competitorCompetitors = "competitors-module--competitor--competitors--1Cq8w";
export const singleCompetitorPage = "competitors-module--single--competitor--page--sv0-s";
export const singleCompetitor = "competitors-module--single-competitor--3fhhg";
export const singleCompetitorTextblock = "competitors-module--single-competitor-textblock--2F8k8";
export const singleCompetitorImage = "competitors-module--single-competitorImage--1BvTb";
export const singleCompetitorTitle = "competitors-module--single--competitor--title--1AHeK";
export const backButtonDiv = "competitors-module--backButton--div--btKvW";
export const backButton = "competitors-module--backButton--3bFru";
export const competitorFullPage = "competitors-module--competitor--fullPage--2-vTi";